var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMounting)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"no-data-text":"データがありません","loading-text":"読込中...","header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"sort-by":"meta.date","sort-desc":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-2 mr-1",attrs:{"icon":"","depressed":"","ripple":false,"loading":_vm.isLoading},on:{"click":_vm.reloadExpenses}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiReload)}})],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","inset":""}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
var menuAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',menuAttrs,false),menuOn),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiDotsVertical)}})],1)]}},{key:"default",fn:function(menu){return [_c('v-list',{attrs:{"disabled":_vm.isLoading,"dense":""}},[_c('v-subheader',[_vm._v("操作")]),_c('v-list-item',{on:{"click":function($event){_vm.showTertiaryColumns = !_vm.showTertiaryColumns; menu.value = false}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.showTertiaryColumns ? '詳細分類を非表示' : '詳細分類を表示')+" ")])],1),_c('DataTableCopyListItemBtn',{attrs:{"disabled":_vm.isLoading,"headers":_vm.headers,"items":_vm.items}})],1)]}}])})],1)]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" ¥"+_vm._s(item.amount.toLocaleString('ja-JP'))+" ")]}},{key:"item.meta.isNonCash",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.meta.isNonCash),callback:function ($$v) {_vm.$set(item.meta, "isNonCash", $$v)},expression:"item.meta.isNonCash"}})]}},{key:"foot",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right pr-4",attrs:{"colspan":_vm.headers.length - 2}},[_vm._v("合計：")]),_c('td',[_vm._v(" ¥"+_vm._s(_vm.totalAmount.toLocaleString('ja-JP'))+" ")]),_c('td')])])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }