import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getExpenses(date, interval, endDate) {
    return axiosIns.get(
      '/v2/report/expenses',
      {
        params: snakecaseKeys({ date, interval, endDate }),
      },
    )
  },
}
