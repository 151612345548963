<template>
  <v-container :key="`expenses-${date}-${interval}-${endDate}`">
    <v-row>
      <v-col><h2>経費一覧</h2></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <IntervalDatePicker
          :key="`interval-date-picker-${date}-${interval}`"
          :year="false"
          class="px-3"
          @date-updated="date = $event"
          @end-date-updated="endDate = $event"
          @interval-updated="interval = Number($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ExpensesTable
          :date="date"
          :interval="interval"
          :end-date="endDate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  ref,
  onMounted,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { startOfMonth, format } from 'date-fns'
import IntervalDatePicker from '@/views/components/util/filter/IntervalDatePicker.vue'
import ExpensesTable from './components/ExpensesTable.vue'

export default {
  components: {
    IntervalDatePicker,
    ExpensesTable,
  },
  setup() {
    const { router, route } = useRouter()

    const defaults = {
      date: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      interval: 2, // monthly
      endDate: null,
    }

    const date = ref(route.value.query.date || defaults.date)
    const interval = ref(Number(route.value.query.interval) || defaults.interval)
    const endDate = ref(route.value.query.endDate || defaults.endDate)

    onMounted(() => {
      router.replace({
        path: route.path,
        query: {
          date: date.value,
          interval: interval.value,
        },
      })
    })

    return {
      date,
      interval,
      endDate,
    }
  },
}
</script>
