<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <v-skeleton-loader
      v-if="isMounting"
      type="table"
    />

    <v-data-table
      v-else
      :loading="isLoading"
      :headers="headers"
      :items="items"
      no-data-text="データがありません"
      loading-text="読込中..."
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      sort-by="meta.date"
      :sort-desc="true"
      :items-per-page="-1"
    >
      <template #top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            icon
            depressed
            :ripple="false"
            :loading="isLoading"
            class="ml-2 mr-1"
            @click="reloadExpenses"
          >
            <v-icon v-text="icons.mdiReload" />
          </v-btn>
          <v-divider
            vertical
            inset
            class="mx-2"
          />
          <v-menu
            bottom
            left
          >
            <template #activator="{ on: menuOn, attrs: menuAttrs }">
              <v-btn
                icon
                v-bind="menuAttrs"
                v-on="menuOn"
              >
                <v-icon v-text="icons.mdiDotsVertical" />
              </v-btn>
            </template>

            <template #default="menu">
              <v-list
                :disabled="isLoading"
                dense
              >
                <v-subheader>操作</v-subheader>
                <v-list-item
                  @click="showTertiaryColumns = !showTertiaryColumns; menu.value = false"
                >
                  <v-list-item-title>
                    {{ showTertiaryColumns ? '詳細分類を非表示' : '詳細分類を表示' }}
                  </v-list-item-title>
                </v-list-item>
                <DataTableCopyListItemBtn
                  :disabled="isLoading"
                  :headers="headers"
                  :items="items"
                />
              </v-list>
            </template>
          </v-menu>
        </v-toolbar>
      </template>

      <template #[`item.amount`]="{ item }">
        ¥{{ item.amount.toLocaleString('ja-JP') }}
      </template>

      <template #[`item.meta.isNonCash`]="{ item }">
        <v-checkbox
          v-model="item.meta.isNonCash"
          hide-details
          dense
          readonly
        />
      </template>

      <template #foot>
        <tfoot>
          <tr>
            <td :colspan="headers.length - 2" class="text-right pr-4">合計：</td>
            <td>
              ¥{{ totalAmount.toLocaleString('ja-JP') }}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  toRefs,
  computed,
  onMounted,
} from '@vue/composition-api'
import {
  mdiReload,
  mdiTrashCanOutline,
  mdiDotsVertical,
} from '@mdi/js'
import { startOfMonth, format } from 'date-fns'

import ExpenseApi from '@/api/v2/report/Expense'
import useCompInit from '@/views/composable/useCompInit'
import useUserPreferences from '@/views/v2-temp/common/composables/useUserPreferences'
import DataTableCopyListItemBtn from '@/views/v2-temp/common/composables/components/DataTableCopyListItemBtn.vue'

export default {
  components: {
    DataTableCopyListItemBtn,
  },
  props: {
    date: {
      type: String,
      required: true,
      default: () => {
        return format(startOfMonth(new Date()), 'yyyy-MM-dd')
      },
    },
    interval: {
      type: Number,
      default: 2,
    },
    endDate: {
      default: null,
      validator: val => (val === null || typeof val === 'string'),
    },
  },
  setup(props) {
    const { date, interval, endDate } = toRefs(props)
    const { isLoading, initWith } = useCompInit()
    const { preferences } = useUserPreferences()

    const isMounting = ref(true)
    const expenses = ref([])

    const showTertiaryColumns = computed({
      get: () => preferences.value['eod_closing:expenses'].showTertiaryColumns,
      set: val => {
        preferences.value['eod_closing:expenses'].showTertiaryColumns = val
      },
    })

    const headers = computed(() => [
      { text: '日付', value: 'meta.date' },
      { text: '主分類', value: 'meta.expensePrimaryLabelName' },
      { text: '副分類', value: 'meta.expenseSecondaryLabelName' },
      ...(showTertiaryColumns.value
        ? [
          { text: '第三分類', value: 'meta.expenseTertiaryLabelName' },
          { text: '第四分類', value: 'meta.expenseQuaternaryLabelName' },
        ] : []
      ),
      { text: '金額', value: 'amount' },
      { text: '非現金', value: 'meta.isNonCash' },
    ])

    const items = computed(() => {
      return expenses.value.map(exp => {
        const attr = exp.attributes || {}
        const meta = exp.meta || {}
        const row = {
          id: attr.id,
          amount: attr.amount || 0,
          meta: {
            date: meta.date,
            isNonCash: meta.isNonCash || false,
            expensePrimaryLabelName: meta.expensePrimaryLabelName || null,
            expenseSecondaryLabelName: meta.expenseSecondaryLabelName || null,
            expenseTertiaryLabelName: meta.expenseTertiaryLabelName || null,
            expenseQuaternaryLabelName: meta.expenseQuaternaryLabelNa || null,
          },
        }

        return row
      })
    })

    const totalAmount = computed(() => {
      return items.value.reduce((sum, item) => {
        return sum + (item.amount || 0)
      }, 0)
    })

    /**
     * API: 諸経費一覧取得
     */
    const getExpenses = async () => {
      isLoading.value = true
      try {
        const res = await ExpenseApi.getExpenses(date.value, interval.value, endDate.value)
        if (res.data.status === 'success') {
          expenses.value = res.data.data.data
        }
      } finally {
        isLoading.value = false
      }
    }

    const reloadExpenses = async () => {
      await Promise.all([
        getExpenses(),
      ])
    }

    onMounted(async () => {
      await initWith([
        getExpenses(),
      ])
      isMounting.value = false
    })

    return {
      // refs
      isMounting,
      showTertiaryColumns,

      // computed
      items,
      headers,
      expenses,
      totalAmount,

      // methods
      reloadExpenses,

      // from useCompInit
      isLoading,

      // icons
      icons: {
        mdiReload,
        mdiTrashCanOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>
